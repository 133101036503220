import { call, put, takeLatest } from 'redux-saga/effects';
import types from './types';
import actions from './actions';
import { GetNews } from '../services/ModerationApi';

function* getNews() {
  try {
    const { teamNews, agencyNews } = yield call(GetNews);
    yield put(actions.getNewsSuccess(agencyNews, teamNews));
  } catch (error) {
    yield put(actions.getNewsFailure(error));
  }
}

function* handleGetNews() {
  yield takeLatest(types.getNews, getNews);
}

export default {
  handleGetNews,
};

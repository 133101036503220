import types from './types';

const getNews = () => ({ type: types.getNews });
const getNewsSuccess = (agencyNews, teamNews) => ({
  type: types.getNewsSuccess,
  agencyNews,
  teamNews,
});
const getNewsFailure = error => ({ type: types.getNewsFailure, error });

export default {
  getNews,
  getNewsSuccess,
  getNewsFailure,
};

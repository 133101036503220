import { createStructuredSelector } from 'reselect';

const getStatisticPart = state => state.statisticsInformation;

const getStatistics = state => getStatisticPart(state).values;

const getStartDay = state => getStatisticPart(state).startDay;

const getEndDay = state => getStatisticPart(state).endDay;

const getRange = createStructuredSelector({ startDay: getStartDay, endDay: getEndDay });

export default {
  getStatistics,
  getRange,
};

import { combineReducers } from 'redux';
import types from './types';

const agencyNewsReducer = (state = null, action) => {
  const { type, agencyNews } = action;
  switch (type) {
    case types.getNewsSuccess:
      return agencyNews;
    default:
      return state;
  }
};

const teamNewsReducer = (state = null, action) => {
  const { type, teamNews } = action;
  switch (type) {
    case types.getNewsSuccess:
      return teamNews;
    default:
      return state;
  }
};

export default combineReducers({ agencyNews: agencyNewsReducer, teamNews: teamNewsReducer });

import { createSelector } from 'reselect';
import { reverse, sortBy } from 'lodash';

const getAgencyNews = (state) => state.news.agencyNews;

const getTeamNews = (state) => state.news.teamNews;

const getTeamNewsSorted = createSelector(
  getTeamNews,
  (news) => news && reverse(sortBy(news, 'createdAt'))
);

const getAgencyNewsSorted = createSelector(
  getAgencyNews,
  (news) => news && reverse(sortBy(news, 'createdAt'))
);

export default {
  getAgencyNewsSorted,
  getTeamNewsSorted,
};

import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import * as Sentry from '@sentry/browser';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from './reducer';
import runSagas from './runSagas';
import persistConfig from './persistConfig';

const logger = (store) => (next) => (action) => {
  if (typeof window !== 'undefined') {
    // console.group(action.type);
    // console.info('dispatching', action);
  }
  const result = next(action);
  if (typeof window !== 'undefined') {
    // console.log('next state', store.getState());
    // console.groupEnd();
  }
  return result;
};

const sentryLogger = () => (next) => (action) => {
  const { type, ...props } = action;

  Sentry.addBreadcrumb({ category: 'action', message: `Dispatching ${type}`, data: props });

  const result = next(action);
  return result;
};

export default function initializeStore() {
  const isServer = typeof window === 'undefined';
  const sagaMiddleware = createSagaMiddleware();
  if (isServer) {
    const store = createStore(reducer, undefined, applyMiddleware(sentryLogger, logger));
    return store;
  }
  const { persistStore, persistReducer } = require('redux-persist');

  const persistedReducer = persistReducer(persistConfig, reducer);

  let store = null;

  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
    store = createStore(
      persistedReducer,
      undefined,
      composeWithDevTools(applyMiddleware(sentryLogger, logger, sagaMiddleware))
    );
  } else {
    // production code
    store = createStore(
      persistedReducer,
      undefined,
      applyMiddleware(sentryLogger, logger, sagaMiddleware)
    );
  }

  store.__persistor = persistStore(store);
  runSagas(sagaMiddleware);
  // console.log(store);
  return store;
}

import { call, spawn, all } from 'redux-saga/effects';
import { values } from 'lodash';
import { agentSagas } from '../agent';
import { coinsSagas } from '../coins';
import { configurationSagas } from '../configuration';
import { conversationSagas } from '../conversations';
import { conversationInformationEntriesSagas } from '../conversationInformationEntries';
import { failureSagas } from '../failure';
import { friendshipsSagas } from '../friendships';
import { galleriesSagas } from '../galleries';
import { imagesSagas } from '../images';
import { linkedDocumentsSagas } from '../linkedDocuments';
import { messagesSagas } from '../messages';
import { newsSagas } from '../news';
import { onlineStateSagas } from '../onlineState';
import { prereservedConversationSagas } from '../prereservedConversations';
import { presentsSagas } from '../presents';
import { profilesSagas } from '../profiles';
import { queuesSagas } from '../queues';
import { reservedConversationSagas } from '../reservedConversation';
import { restroomSagas } from '../restroom';
import { sessionSagas } from '../session';
import { statisticsInformationSagas } from '../statisticsInformation';
import { userDialogEntriesSagas } from '../userDialogEntries';
import { websocketSagas } from '../websocket';

const sagas = [
  agentSagas,
  coinsSagas,
  configurationSagas,
  conversationInformationEntriesSagas,
  conversationSagas,
  failureSagas,
  friendshipsSagas,
  galleriesSagas,
  imagesSagas,
  linkedDocumentsSagas,
  messagesSagas,
  newsSagas,
  onlineStateSagas,
  prereservedConversationSagas,
  presentsSagas,
  profilesSagas,
  queuesSagas,
  restroomSagas,
  reservedConversationSagas,
  sessionSagas,
  statisticsInformationSagas,
  userDialogEntriesSagas,
  websocketSagas,
].reduce((array, item) => [...array, ...values(item)], []);

function* sagaSpawn(saga) {
  while (true) {
    try {
      yield call(saga);
      break;
    } catch (error) {
      console.error(error);
    }
  }
}

function* rootSaga() {
  yield all(sagas.map((saga) => spawn(sagaSpawn, saga)));
}

export default function runSagas(middleware) {
  middleware.run(rootSaga);
}

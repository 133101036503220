import types from './types';

const getStatistics = (start, end) => ({ type: types.getStatistics, start, end });
const getStatisticsSuccess = statistics => ({ type: types.getStatisticsSuccess, statistics });
const getStatisticsFailure = error => ({ type: types.getStatisticsFailure, error });

export default {
  getStatistics,
  getStatisticsSuccess,
  getStatisticsFailure,
};

import { confirmMeetingProhibitionType } from './types';

const initialState = {
  status: true,
  control: 'HalloWelt',
};

const confirmMeetingProhibitionReducer = (state = initialState, action) => {
  switch (action.type) {
    case confirmMeetingProhibitionType:
      return {
        ...state,
        status: false,
      };
    default:
      return state;
  }
};

export default confirmMeetingProhibitionReducer;

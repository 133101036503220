import { combineReducers } from 'redux';
import agent, { agentTypes } from '../agent';
import coins from '../coins';
import composerConfiguration from '../composerConfiguration';
import configuration from '../configuration';
import conversationInformationEntries from '../conversationInformationEntries';
import conversations from '../conversations';
import featuredGallery from '../featuredGallery';
import friendships from '../friendships';
import galleries from '../galleries';
import images from '../images';
import linkedDocuments from '../linkedDocuments';
import messages from '../messages';
import news from '../news';
import onlineState from '../onlineState';
import prereservedConversations from '../prereservedConversations';
import presents from '../presents';
import profiles from '../profiles';
import queues from '../queues';
import reservedConversation from '../reservedConversation';
import restroom from '../restroom';
import session from '../session';
import statisticsInformation from '../statisticsInformation';
import userDialogEntries from '../userDialogEntries';
import users from '../users';
import websocket from '../websocket';
import confirmMeetingProhibitionReducer from '../confirmMeetingProhibition/reducer';

const reducer = combineReducers({
  agent,
  coins,
  composerConfiguration,
  configuration,
  conversationInformationEntries,
  conversations,
  featuredGallery,
  friendships,
  galleries,
  images,
  linkedDocuments,
  messages,
  news,
  onlineState,
  prereservedConversations,
  presents,
  profiles,
  queues,
  reservedConversation,
  restroom,
  session,
  statisticsInformation,
  userDialogEntries,
  users,
  confirmMeetingProhibitionReducer,
  /*   websocket, */
});

const coreReducer = (state, action) => {
  const stateValue = action.type === agentTypes.logoutSuccess ? undefined : state;
  return reducer(stateValue, action);
};

export default coreReducer;

import { takeEvery, put, fork, retry } from 'redux-saga/effects';
import Router from 'next/router';
import { agentActions, agentTypes } from '../agent';
import { reservedConversationTypes } from '../reservedConversation';
import { prereservedConversationTypes } from '../prereservedConversations';
import { ReportFailure } from '../services/ModerationApi';

function* sendFailureReport(error) {
  // Remove sendFailureReport to avoid server slowdowns from request logs
  // try {
  //   const { config, data: errorMessage, status, statusText } = error;
  //   const { url, method, headers, params, data: postData } = config || {};
  //   yield retry(6, 30000, ReportFailure, url, {
  //     errorMessage,
  //     status,
  //     statusText,
  //     method,
  //     headers,
  //     params,
  //     postData,
  //   });
  // } catch (error) {
  // }
}

function* checkForFailure({ type, error }) {
  if (type && type.toLowerCase().endsWith('failure')) {
    const { data, status } = error;
    if (status === 900) {
      Router.push('/maintenance');
    } else if (data && data.statusCode === 20000) {
      yield put(agentActions.logout());
    } else {
      yield fork(sendFailureReport, error);
    }
  }
}

function* handleFailure() {
  yield takeEvery('*', checkForFailure);
}

export default {
  handleFailure,
};

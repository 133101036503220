import storage from 'redux-persist/lib/storage/session';
import { createTransform } from 'redux-persist';

const persistConfig = {
  key: 'nextjs',
  storage,
  transforms: [
    createTransform(
      ({ isLoading, ...state }) => state,
      (state) => state,
      { whitelist: 'agent' }
    ),
  ],
  whitelist: [
    'agent',
    'configuration',
    'composerConfiguration',
    'conversationInformationEntries',
    'conversations',
    'friendships',
    'galleries',
    'images',
    'linkedDocuments',
    'messages',
    'news',
    'prereservedConversations',
    'presents',
    'profiles',
    'reservedConversation',
    'restroom',
    'session',
    'statisticsInformation',
    'userDialogEntries',
    'users',
  ],
};

export default persistConfig;

import { put, call, takeLatest } from 'redux-saga/effects';
import types from './types';
import actions from './actions';
import { GetStatistics } from '../services/ModerationApi';

function* retrieveStatistics({ start, end }) {
  try {
    const statistics = yield call(GetStatistics, start, end);
    yield put(actions.getStatisticsSuccess(statistics));
  } catch (error) {
    yield put(actions.getStatisticsFailure(error));
  }
}

function* handleGet() {
  yield takeLatest(types.getStatistics, retrieveStatistics);
}

export default {
  handleGet,
};

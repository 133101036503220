import App from 'next/app';
import React from 'react';
import { CookiesProvider, Cookies } from 'react-cookie';
import { Provider, ReactReduxContext } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { PersistGate } from 'redux-persist/integration/react';
import { appWithTranslation } from '../components/i18n';
import { SetToken } from '../components/services/ModerationApi';
import { DateLocaleContextProvider } from '../components/DateLocaleContext';
import { wrapper } from '../components/store';

Sentry.init({
  dsn: 'https://ce63efa712ae4556be268354726bfe04@sentry.io/1453946',
  maxBreadcrumbs: 50,
  beforeBreadcrumb: (breadcrumb) => {
    const { category } = breadcrumb;
    return category !== 'console' ? breadcrumb : null;
  },
  environment: process.env.NODE_ENV,
});

class ChatApp extends App {
  constructor(props) {
    super();
    const { pageProps } = props;
    const cookie = pageProps?.cookie;
    const tokenEnabler = new Cookies(cookie);
    SetToken(tokenEnabler.get('Token'));
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps } = this.props;
    const cookies = new Cookies(pageProps?.cookie);
    return (
      <CookiesProvider cookies={cookies}>
        <ReactReduxContext.Consumer>
          {({ store }) => {
            return (
              <PersistGate persistor={store.__persistor}>
                <DateLocaleContextProvider language="de">
                  <Component {...pageProps} />
                </DateLocaleContextProvider>
              </PersistGate>
            );
          }}
        </ReactReduxContext.Consumer>
      </CookiesProvider>
    );
  }
}

export default wrapper.withRedux(appWithTranslation(ChatApp));

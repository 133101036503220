import { combineReducers } from 'redux';
import { formatISO, startOfDay, subDays } from 'date-fns';
import types from './types';

const statisticsValueReducer = (state = {}, action) => {
  switch (action.type) {
    case types.getStatisticsSuccess:
      return action.statistics;
    default:
      return state;
  }
};

const startDayReducer = (state = formatISO(startOfDay(subDays(new Date(), 1))), action) => {
  switch (action.type) {
    case types.getStatistics:
      return action.start || state;
    default:
      return state;
  }
};

const endDayReducer = (state = formatISO(startOfDay(subDays(new Date(), 1))), action) => {
  switch (action.type) {
    case types.getStatistics:
      return action.end || state;
    default:
      return state;
  }
};

export default combineReducers({
  values: statisticsValueReducer,
  startDay: startDayReducer,
  endDay: endDayReducer,
});
